<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="auction.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="auction.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <form @submit="doSubmit">
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="minimumBid"
                  >
                    {{ this.i18n('auction.fields.minimumBid') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="crud-form-1"
                      name="minimumBid"
                      type="number"
                      min="0"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.minimumBid"
                      :placeholder="i18n('auction.fields.minimumBid')"
                      required
                    />
                    <!-- <ErrorMessage class="text-primary-3" name="minimumBid" /> -->
                  </div>
                </div>
                <!-- <div class="grid grid-cols-12 mt-5 items-center">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4"
                    for="curreny"
                  >
                    {{ i18n('auction.fields.currency_code') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <TomSelect
                      v-model="model.currency"
                      class="w-full mt-2 sm:mt-0"
                      name="curreny"
                    >
                      <option value="USD">{{ isRTL ? 'دولار' : 'USD' }}</option>
                      <option value="EGP">{{ isRTL ? 'جنية' : 'EGP' }}</option>
                    </TomSelect>
                    <ErrorMessage class="text-primary-3" name="curreny" />
                  </div>
                </div> -->
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="minBidIncrement"
                  >
                    {{ i18n('auction.fields.minBidIncrement') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="crud-form-1"
                      type="number"
                      min="0"
                      name="minBidIncrement"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.minBidIncrement"
                      :placeholder="i18n('auction.fields.minBidIncrement')"
                      required
                    />
                    <!-- <ErrorMessage
                      class="text-primary-3"
                      name="minBidIncrement"
                    /> -->
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="maxBidIncrement"
                  >
                    {{ i18n('auction.fields.maxBidIncrement') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="crud-form-1"
                      type="number"
                      name="maxBidIncrement"
                      min="0"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.maxBidIncrement"
                      :placeholder="i18n('auction.fields.maxBidIncrement')"
                      required
                    />
                    <!-- <ErrorMessage
                      class="text-primary-3"
                      name="maxBidIncrement"
                    /> -->
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="minTargetPrice"
                  >
                    {{ i18n('auction.fields.minTargetPrice') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="crud-form-1"
                      type="number"
                      name="minTargetPrice"
                      min="0"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.minTargetPrice"
                      :placeholder="i18n('auction.fields.minTargetPrice')"
                      required
                    />
                    <!-- <ErrorMessage
                      class="text-primary-3"
                      name="minTargetPrice"
                    /> -->
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="maxTargetPrice"
                  >
                    {{ i18n('auction.fields.maxTargetPrice') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="crud-form-1"
                      type="number"
                      name="maxTargetPrice"
                      min="0"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.maxTargetPrice"
                      :placeholder="i18n('auction.fields.maxTargetPrice')"
                      required
                    />
                    <!-- <ErrorMessage
                      class="text-primary-3"
                      name="maxTargetPrice"
                    /> -->
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="auctionDuration"
                  >
                    {{ i18n('auction.fields.auctionDuration') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 relative">
                    <input
                      id="crud-form-1"
                      type="number"
                      name="auctionDuration"
                      min="0"
                      class="form-control w-full"
                      v-model="model.auctionDuration"
                      :placeholder="i18n('auction.fields.auctionDuration')"
                    />
                    <!-- <ErrorMessage
                      class="text-primary-3"
                      name="auctionDuration"
                    /> -->
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5 items-center">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4"
                    for="durationUnit"
                  >
                    {{ i18n('auction.fields.durationUnit') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <TomSelect
                      v-model="model.durationUnit"
                      class="w-full mt-2 sm:mt-0"
                      name="durationUnit"
                    >
                      <option value="minutes">
                        {{ isRTL ? 'دقائق' : 'Minutes' }}
                      </option>
                      <option value="hours">
                        {{ isRTL ? 'ساعات' : 'Hours' }}
                      </option>
                      <option value="days">
                        {{ isRTL ? 'أيام' : 'Days' }}
                      </option>
                    </TomSelect>
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5">
                  <h4
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                  >
                    {{ i18n('auction.fields.startDate') }}
                  </h4>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                    <div class="relative mx-auto">
                      <!-- <div
                        class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                      >
                        <CalendarIcon class="w-4 h-4" />
                      </div> -->
                      <!-- <Litepicker
                        v-model="model.startDate"
                        name="date"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true
                          }
                        }"
                        class="form-control"
                        :class="isRTL ? 'pr-12' : 'pl-12'"
                      /> -->
                      <input
                        type="datetime-local"
                        v-model="model.startDate"
                        class="form-control"
                        ref="datetime"
                        :class="isRTL ? 'pr-12' : 'pl-12'"
                        @click="focusInput"
                      />
                      <!-- <ErrorMessage class="text-primary-3" name="date" /> -->
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5">
                  <label
                    class="text-lg font-medium leading-none items-center mr-2 lg:col-span-4 col-span-12"
                    for="items"
                  >
                    {{ i18n('auction.fields.items') }}
                  </label>
                  <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 relative">
                    <input
                      id="crud-form-1"
                      type="text"
                      name="auctionDuration"
                      class="form-control w-full"
                      v-model="items[0].value"
                      :placeholder="i18n('auction.fields.shopifyId')"
                    />
                  </div>
                  <!-- <div
                    class="relative px-4 py-4 bg-slate-50 rounded-md lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  >
                    <div>
                      <div class="form-inline mt-5 items-start first:mt-0">
                        <div class="flex-1">
                          <div
                            class="grid grid-cols-12 items-center mt-5 first:mt-0"
                            v-for="(item, idx) in items"
                            :key="idx"
                          >
                            <div class="Field-group flex-1 col-span-11">
                              <input
                                id="crud-form-1"
                                type="text"
                                name="items"
                                class="form-control w-full"
                                v-model="item.value"
                                :placeholder="i18n('auction.fields.shopifyId')"
                              />
                            </div>
                            <div
                              class="flex items-center justify-center text-slate-500 col-span-1"
                              @click.prevent="deleteItem(idx)"
                            >
                              <Trash2Icon class="w-5 h-5 cursor-pointer" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-5 first:mt-0">
                        <button
                          class="btn btn-outline-secondary border-dashed w-full text-black"
                          @click="addItem"
                          :disabled="!disableAddItem"
                        >
                          <PlusIcon class="w-4 h-4 mr-2" />
                          {{ i18n('auction.fields.addItem') }}
                        </button>
                      </div>
                    </div>
                  </div> -->
                </div>
                <!-- END -->
                <div class="flex justify-center items-center mt-10">
                  <AppButton
                    type="button"
                    class="btn bg-theme-31 text-white cursor-pointer"
                    :class="!saveLoading ? 'w-24' : ''"
                    :disabled="saveLoading || findLoading"
                    :loading="saveLoading"
                    @click="doSubmit"
                  >
                    <strong>{{ i18n('common.save') }}</strong>
                    <template v-slot:loading>
                      <app-i18n code="common.loading"></app-i18n>
                      <LoadingIcon
                        icon="three-dots"
                        color="#FFFFFF"
                        style="margin: 0 4px"
                      />
                    </template>
                  </AppButton>
                  <button
                    type="button"
                    class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                    @click="doCancel"
                  >
                    <app-i18n code="common.cancel"></app-i18n>
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { AuctionModel } from '@/store/auction/auction-model'

const { fields } = AuctionModel
const formSchema = new FormSchema([
  fields.id,
  fields.minimumBid,
  fields.currency,
  fields.minBidIncrement,
  fields.maxBidIncrement,
  fields.minTargetPrice,
  fields.maxTargetPrice,
  fields.auctionDuration,
  fields.durationUnit,
  fields.items,
  fields.startDate
])

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      errorMessage: '',
      rules: formSchema.rules(),
      items: [{ value: '' }]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'auction/form/auctionRecord',
      findLoading: 'auction/form/findLoading',
      saveLoading: 'auction/form/saveLoading'
    }),
    disableAddItem() {
      return this.items.every(item => Boolean(item.value.trim()))
    },
    isEditing() {
      return !!this.id
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.auction')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.auction')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(this.record || {})
    this.model.currency = this.record?.currency || 'EGP'
    this.model.durationUnit = this.record?.durationUnit || 'days'
    this.items =
      this.record && this.record.items.length > 0
        ? this.record.items.map(item => ({ value: item.id }))
        : [{ value: '' }]
  },
  methods: {
    ...mapActions({
      doNew: 'auction/form/doNew',
      doFind: 'auction/form/doFind',
      doCreate: 'auction/form/doCreate',
      doUpdate: 'auction/form/doUpdate'
    }),
    focusInput() {
      this.$refs.datetime.showPicker()
    },
    addItem() {
      this.items.push({ value: '' })
    },
    deleteItem(idx) {
      if (this.items.length === 1) {
        this.items[0].value = ''
        return
      }
      this.items.splice(idx, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (const key in this.model) {
        if (key === 'id') continue
        if (key === 'items') {
          if (this.items.length === 0 || this.items[0].value.length === 0) {
            this.errorMessage = `${
              this.isRTL ? 'الرجاء إدخال عنصر ' : 'Please enter an item'
            }`
            return false
          }
        }
        if (!this.model[key] || this.model.length === 0) {
          this.errorMessage = `${
            this.isRTL ? 'الرجاء إدخال ' : 'Please enter a valid'
          } ${this.i18n(`auction.fields.${key}`)}`
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        return Message.error(this.errorMessage)
      }
      this.model.items = this.items.map(item => item.value)
      const { id, ...values } = formSchema.cast(this.model)
      values.startDate = new Date(values.startDate).toISOString()
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values
        })
      } else {
        await this.doCreate(values)
      }
    }
  }
}
</script>
