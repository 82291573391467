/* eslint-disable */

import { GenericModel } from '@/shared/model/generic-model'
import { i18n } from '@/locales/i18n'
import IdField from '@/shared/fields/id-field'
import StringField from '@/shared/fields/string-field'
// import JsonField from '@/shared/fields/json-field'
import StringArrayField from '@/shared/fields/string-array-field'
// import BooleanField from '@/shared/fields/boolean-field';
import DateTimeField from '@/shared/fields/date-time-field'
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field'
import DecimalField from '@/shared/fields/decimal-field'
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`auction.fields.${name}`)
}

const fields = {
  id: new IdField('id', label('id')),
  minimumBid: new DecimalField('minimumBid', label('minimumBid')),
  currency: new StringField('currency', label('currency')),
  minBidIncrement: new DecimalField(
    'minBidIncrement',
    label('minBidIncrement')
  ),
  maxBidIncrement: new DecimalField(
    'maxBidIncrement',
    label('maxBidIncrement')
  ),
  minTargetPrice: new DecimalField('minTargetPrice', label('minTargetPrice')),
  maxTargetPrice: new DecimalField('maxTargetPrice', label('maxTargetPrice')),
  auctionDuration: new DecimalField(
    'auctionDuration',
    label('auctionDuration')
  ),
  durationUnit: new StringField('durationUnit', label('durationUnit')),
  startDate: new DateTimeField('startDate', label('startDate')),
  items: new StringArrayField('items', label('items'))

  // createdAt: new DateTimeField('createdAt', label('createdAt')),
  // updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  // createdBy: new StringField('createdBy', label('createdBy')),
  // updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
}

export class AuctionModel extends GenericModel {
  static get fields() {
    return fields
  }
}
